<template>
  <fade-transition :duration="150">
    <a-banner
      v-show="isBannerVisible"
      :class="bannerClass"
      :banner-settings="bannerSettings"
      no-advertisement-label
      v-bind="$attrs"
    />
  </fade-transition>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import ABanner from 'shared/ABanner'
import { mapGetters } from 'vuex'
import { debounce } from 'debounce'
import { STICKY_BANNER_POSITION } from 'enums/banners/sticky-banner'
import { REFS } from 'enums/external-refs'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const STICKY_BANNER_BASE_CLASS = 'banner-sticky'

const CHECK_COLLISION_METHOD_NAME_BY_BANNER_POSITION = {
  [STICKY_BANNER_POSITION.TOP]: 'checkCollisionWithTopStickyBanner',
  [STICKY_BANNER_POSITION.BOTTOM]: 'checkCollisionWithBottomStickyBanner'
}

const CUSTOM_STICKY_WIDGET_ID = 'wtgSticky'

export default {
  name: 'AStickyBanner',
  mixins: [hydrationHelpers],
  components: {
    ABanner
  },
  props: {
    stickyPosition: propValidator([PROP_TYPES.STRING], true, null, value =>
      Object.values(STICKY_BANNER_POSITION).includes(value)
    ),
    bannerSettings: propValidator([PROP_TYPES.OBJECT], false, () => {})
  },
  data() {
    return {
      isBannersOverlapped: false,
      customStickyToBottomWidgetEl: null,
      customStickyToBottomWidgetDimensions: null,
      setStickyBannerVisibilityDebounced: debounce(async function() {
        if (!this.currentBannerSettings) return

        const bannersThatCanBeOverlapped = Array.from(
          document.querySelectorAll(`[data-ref=${REFS.BANNER}]`)
        ).filter(this.isBannerRelevant)

        const overlappedBannersState = bannersThatCanBeOverlapped.map(
          this.checkCollisionWithCurrentBanner
        )

        /**
         * Due to the fact that putting one banner on top of another one is a violation
         * of Google's rules, we need to hide any sticky banner that overlaps regular
         * banners on the page
         */
        this.isBannersOverlapped = overlappedBannersState.some(
          isOverlapped => isOverlapped
        )
      }, 200),
      clearInterval: null
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop',
      isFooterInView: 'element-visibility/isFooterInView',
      isHeaderAtTop: 'isHeaderAtTop'
    }),
    isStickyToBottomBannerVisible() {
      return !this.isBannersOverlapped && !this.isFooterInView
    },
    isStickyToTopBannerVisible() {
      return !this.isBannersOverlapped && this.isHeaderAtTop
    },
    isBannerVisible() {
      return this.stickyPosition === STICKY_BANNER_POSITION.TOP
        ? !this.isStickyToTopBannerVisible
        : this.isStickyToBottomBannerVisible
    },
    bannerClass() {
      const specificStickyBannerClass =
        this.stickyPosition === STICKY_BANNER_POSITION.TOP ? 'top' : 'bottom'

      return {
        [STICKY_BANNER_BASE_CLASS]: true,
        [specificStickyBannerClass]: true
      }
    },
    currentBannerSettings() {
      return this.bannerSettings[
        this.$_hydrationHelpers_getCurrentBreakpoint().name
      ]
    },
    currentBannerHeight() {
      if (!this.currentBannerSettings) return 0

      return this.currentBannerSettings.sizes[1]
    },
    checkCollisionWithCurrentBanner() {
      return this[
        CHECK_COLLISION_METHOD_NAME_BY_BANNER_POSITION[this.stickyPosition]
      ]
    },
    bannerVisibilityRecalculationTrigger() {
      return JSON.stringify({
        scrollTop: this.scrollTop,
        windowWidth: this.$_hydrationHelpers_windowWidth,
        windowHeight: this.$_hydrationHelpers_windowHeight
      })
    }
  },
  watch: {
    isStickyToBottomBannerVisible: {
      handler() {
        if (!this.customStickyToBottomWidgetEl) return

        this.setStickyToBottomCustomWidgetVisibility()
      }
    },
    bannerVisibilityRecalculationTrigger: {
      immediate: true,
      handler() {
        if (!process.client) return

        if (this.clearInterval) {
          this.clearInterval()
        }

        // in case custom widget was not found, visibility recalculation will
        // still happen
        this.setStickyBannerVisibilityDebounced()

        const { clearInterval } = this.$helper.pollUntil({
          fn: () => {
            this.customStickyToBottomWidgetEl = document.querySelector(
              `#${CUSTOM_STICKY_WIDGET_ID}`
            )

            this.customStickyToBottomWidgetDimensions = this.customStickyToBottomWidgetEl.getBoundingClientRect()
            this.setStickyBannerVisibilityDebounced()
          },
          condition: () =>
            !!document.querySelector(`#${CUSTOM_STICKY_WIDGET_ID}`),
          executeOnTimeoutFn: this.setStickyBannerVisibilityDebounced
        })
        this.clearInterval = clearInterval
      }
    }
  },
  methods: {
    setStickyToBottomCustomWidgetVisibility() {
      this.customStickyToBottomWidgetEl.style.visibility = this
        .isStickyToBottomBannerVisible
        ? 'visible'
        : 'hidden'
    },
    /**
     * Sticky banners cannot overlap each other, so they are discarded
     * Empty banners are also discarded since they are not displayed to the user
     */
    isBannerRelevant(bannerEl) {
      return (
        bannerEl.firstChild.clientHeight &&
        !bannerEl.className.includes(STICKY_BANNER_BASE_CLASS)
      )
    },
    checkCollisionWithTopStickyBanner(banner) {
      const bannerRect = banner.getBoundingClientRect()

      const collisionOffset =
        bannerRect.top < 0
          ? banner.clientHeight - this.$_hydrationHelpers_menuHeight
          : this.currentBannerHeight + this.$_hydrationHelpers_menuHeight

      return Math.abs(bannerRect.top) <= collisionOffset
    },
    checkCollisionWithBottomStickyBanner(banner) {
      const bannerRect = banner.getBoundingClientRect()
      const distanceFromBottomToBanner = window.innerHeight - bannerRect.bottom

      const { height: customStickyToBottomWidgetHeight } =
        this.customStickyToBottomWidgetDimensions || {}

      const bannerHeight =
        customStickyToBottomWidgetHeight || this.currentBannerHeight

      const collisionOffset =
        distanceFromBottomToBanner < 0 ? banner.clientHeight : bannerHeight

      return Math.abs(distanceFromBottomToBanner) <= collisionOffset
    }
  },
  beforeDestroy() {
    if (this.customStickyToBottomWidgetEl) {
      this.$helper.removeNode(this.customStickyToBottomWidgetEl)

      this.customStickyToBottomWidgetEl = null
    }
  }
}
</script>

<style lang="scss">
.banner-sticky {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: $z-index-sticky-banner;
  display: none;

  .banner__wrapper {
    margin: 0 auto;
  }

  &.bottom {
    bottom: 0;
  }

  &.bottom.unit__outer-wrapper {
    background: transparent;
  }

  &.top {
    top: 76px;

    .banner__overlay-for-sticky {
      display: block;
      position: absolute;
      top: -11px;
      left: 0;
      right: 0;
      width: 100%;
      height: 12px;
      background: $c--white;
    }
  }

  &.unit__outer-wrapper {
    margin: 0;
    background: rgba($c--black, 0.6);
  }

  @include mobile {
    display: block;
  }
}
</style>
