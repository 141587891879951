<template>
  <div v-observe-visibility="visibilityOptions">
    <a-spinner v-if="isVisible && !isEbookComponentLoaded" />
    <a-ebook
      v-if="isVisible"
      v-show="isEbookComponentLoaded"
      v-bind="$attrs"
      v-on="$listeners"
      @hook:mounted="onEbookMount"
    />
  </div>
</template>

<script>
import ASpinner from 'shared/ASpinner'

export default {
  name: 'AEbookLazyLoad',
  components: {
    ASpinner,
    AEbook: () => import('./index')
  },
  data() {
    return {
      isVisible: false,
      isEbookComponentLoaded: false,
      visibilityOptions: {
        callback: this.loadEbookComponent,
        once: true
      }
    }
  },
  methods: {
    loadEbookComponent(isVisible) {
      if (isVisible) {
        this.isVisible = true
      }
    },
    onEbookMount() {
      this.isEbookComponentLoaded = true
      this.$emit('mounted')
    }
  }
}
</script>
