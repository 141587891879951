<template>
  <div class="all-news__wrapper">
    <a-visibility hide :on="[$breakpoint.mobile]">
      <a-lazy-hydrate when-visible>
        <h1 class="all-news__title click-spam-left-padding title">
          Forexlive Latest News
        </h1>
      </a-lazy-hydrate>
    </a-visibility>
    <a-card class="all-news__card skip-mobile-paddings">
      <a-visibility show :on="[$breakpoint.mobile]">
        <div class="all-news__title-wrapper">
          <h1 class="all-news__title click-spam-left-padding title">
            Forexlive Latest News
          </h1>
          <a-join-telegram class="all-news__join-telegram" />
        </div>
      </a-visibility>
      <!-- TODO animation requirements are to be discussed with the client -->
      <!--      <a-in-progress :in-progress="feedRefreshInProgress">-->
      <a-article-list :articles="articles" class="all-news__article-list">
        <template v-slot="{ article, position }">
          <!--            <a-in-progress-->
          <!--              :in-progress="slotRefreshInProgress[position]"-->
          <!--              :align-spinner="SPINNER_ALIGNMENT.CENTER"-->
          <!--            >-->
          <a-lazy-hydrate when-visible>
            <a-article-slot
              v-bind="article"
              v-observe-visibility="getArticleVisibilityOptions(position)"
              :expandable="true"
              :image-viewport-optimization="isInViewport(position)"
              class="all-news__article"
            >
              <a-banner
                slot="bottom-expanded-content"
                slot-scope="{ isContentExpanded }"
                v-if="isContentExpanded"
                :banner-settings="
                  BANNER_SETTINGS.ALL_NEWS.EXPANDED_CONTENT_BANNER
                "
                class="all-news__banner"
              />
            </a-article-slot>
          </a-lazy-hydrate>
          <!--            </a-in-progress>-->
        </template>

        <template v-slot:banner="{ position }">
          <!--          <a-video-banner-->
          <!--            v-if="isVideoBannerVisible(position)"-->
          <!--            :video-banner-type="VIDEO_BANNER_TYPE.ALL_NEWS"-->
          <!--            class="all-news__video-banner"-->
          <!--          />-->
          <a-banner
            v-if="isBannerVisible(position)"
            :banner-settings="getBannerSettings(position)"
            use-random-banner-id
            class="all-news__banner"
          />
        </template>

        <template v-slot:video="{ position }">
          <a-video-slot
            v-if="isVideoVisible(position)"
            :video-settings="videoSettings"
            class="all-news__video"
          />
        </template>
      </a-article-list>
      <!--      </a-in-progress>-->
      <a-see-more-less
        :page="pagination.page"
        :pages-count="pagesCount"
        slot="bottom-section"
        :is-expanded="false"
        :in-progress="inProgress"
        class="all-news__see-more-less"
        @input="getNextArticleChunk"
      />
    </a-card>

    <a-url-pagination
      :item-selector="ARTICLE_SLOT_SELECTOR"
      :item-count="articles.length"
      :pagination.sync="pagination"
      :initial-page="initialPage"
      :pages-count="pagesCount"
      :non-paginated-url="canonicalUrlWithoutPagination"
    />
    <a-refresh-listener
      :articles.sync="articles"
      :feed-refresh-in-progress.sync="feedRefreshInProgress"
      :slot-refresh-in-progress.sync="slotRefreshInProgress"
      :fetch-data-fn="fetchLoadedArticles"
      :settings="$options.consts.AUTO_REFRESH_SETTINGS.ALL_NEWS"
      :target-category-slug="
        $options.consts.SPECIAL_REFRESH_CATEGORY_SLUG.ALL_NEWS
      "
      @feed-refreshed="$_disqusCommentCount_updateCommentCounts"
    />

    <a-sticky-banner
      v-if="isArticleSlotInsideOrAboveViewport"
      :sticky-position="STICKY_BANNER_POSITION.BOTTOM"
      :banner-settings="BANNER_SETTINGS.ALL_NEWS.STICKY_TO_BOTTOM"
    />

    <!--    <all-news-onboarding-flow-->
    <!--      v-if="isOnboardingFlowVisible"-->
    <!--      :is-ebook-loaded="$_ebookDataFetch_isEbookLoaded"-->
    <!--    />-->

    <a-pagination-links-for-google
      :page="pagination.page"
      :pages-count="pagesCount"
      :non-paginated-url="canonicalUrlWithoutPagination"
    />

    <a-ebook-form-modal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import mixins from '@/utils/mixins'
import ACard from 'shared/ACard'
import AArticleList from 'shared/AArticleList'
import AArticleSlot from 'shared/AArticleSlot'
import {
  BANNER_SETTINGS,
  getInsideListBannerSettingsKey
} from 'enums/banners/banner-settings'
import { aspectRatios } from 'enums/aspectRatios'
import AUrlPagination from 'shared/AUrlPagination'
import { SPINNER_ALIGNMENT } from 'shared/AInProgress'
import ARefreshListener from 'shared/ARefreshListener'
import { getPageNumberFromRoute, createPrefilledArray } from '@/plugins/helper'
import {
  AUTO_REFRESH_SETTINGS,
  SPECIAL_REFRESH_CATEGORY_SLUG
} from 'enums/article-auto-refresh'
import { OBSERVER_CALLBACK_DEFAULT_THROTTLE } from 'enums/mutation-observer'
import AStickyBanner from 'shared/AStickyBanner'
import { STICKY_BANNER_POSITION } from 'enums/banners/sticky-banner'
import ABanner from 'shared/ABanner'
// import AVideoBanner from 'shared/AVideoBanner'
import { VIDEO_BANNER_TYPE } from 'enums/video-banner'
import { PAGE_SCHEME_TYPE } from 'enums/pageSchemes'
import { META_PARAM } from 'enums/fl-seo'
// import { ONBOARDING_STORAGE_KEY } from 'enums/onboarding/general'
import { DISQUS_SOURCE } from 'shared/AActionIcon/_shared/CommentCount/enums'
import APaginationLinksForGoogle from 'shared/APaginationLinksForGoogle'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import AEbookFormModal from 'shared/AEbookFormModal/index.vue'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const DEFAULT_TAKE = 25
// const VIDEO_BANNER_POSITION = 2
const BANNER_POSITION = 7
const BANNERS_COUNT = 5
const VIDEO_POSITION = 9
const ARTICLE_SLOT_SELECTOR = '.all-news__article'
const ARTICLE_POSITION_TO_DISPLAY_STICKY_BANNER = 3
const ARTICLE_SLOT_COUNT_IN_VIEWPORT = 2

export default {
  name: 'Homepage',
  scrollToTop: true,
  mixins: [
    mixins.setPageLoaded,
    mixins.articleDataHandler,
    mixins.bannerRotation,
    mixins.ebookDataFetchMixinFactory(),
    mixins.disqusCommentCountMixinFactory({ source: DISQUS_SOURCE.FL }),
    hydrationHelpers
  ],
  components: {
    // AllNewsOnboardingFlow: () =>
    //   import('@/components/_sections/all-news/AllNewsOnboardingFlow'),
    // AVideoBanner,
    ABanner,
    AStickyBanner,
    ACard,
    AArticleList,
    AArticleSlot,
    AVideoSlot: hydrateWhenVisible(() => import('shared/AVideoSlot')),
    ASeeMoreLess: hydrateWhenVisible(() => import('shared/ASeeMoreLess'), {
      props: ['page', 'pages-count', 'is-expanded', 'in-progress']
    }),
    AJoinTelegram: hydrateWhenVisible(() => import('shared/AJoinTelegram')),
    AUrlPagination,
    // AInProgress,
    ARefreshListener,
    APaginationLinksForGoogle,
    AEbookFormModal
  },
  consts: { AUTO_REFRESH_SETTINGS, SPECIAL_REFRESH_CATEGORY_SLUG },
  head() {
    return this.$generateMetaTags({
      titleParam: META_PARAM.ALL_NEWS,
      descriptionParam: META_PARAM.ALL_NEWS,
      schemes: [
        { type: PAGE_SCHEME_TYPE.ORGANIZATION },
        { type: PAGE_SCHEME_TYPE.WEB_SITE },
        ...this.$helper.generateVideoSchemasFromResponse([
          this.videoSettings?.Video,
          ...this.articles.map(article => article.video)
        ])
      ],
      source: 'seo/homepageSeo'
    })
  },
  async asyncData(ctx) {
    try {
      const dispatch = ctx.store.dispatch
      const initialPage = getPageNumberFromRoute(ctx.route)

      const pagination = {
        take: DEFAULT_TAKE,
        page: initialPage
      }

      const requests = [
        dispatch('news/requestAllNews', pagination),
        dispatch('categories/requestAllNewsVideo')
      ]

      const [
        { allNews, pagesCount, lastModified },
        videoSettings
      ] = await Promise.all(requests)

      return {
        articles: allNews,
        feedRefreshInProgress: false,
        slotRefreshInProgress: createPrefilledArray(allNews.length, false),
        videoSettings,
        initialPage,
        pagination,
        pagesCount,
        lastModified
      }
    } catch (err) {
      ctx.app.$errorHandler(err, ctx, {
        showErrorPage: true,
        componentName: 'AllNews (Homepage)'
      })
    }
  },
  data() {
    const initialPage = getPageNumberFromRoute(this.$route)

    return {
      SPINNER_ALIGNMENT,
      BANNER_SETTINGS,
      ARTICLE_SLOT_SELECTOR,
      aspectRatios,
      pagesCount: 0,
      initialPage,
      pagination: {
        take: DEFAULT_TAKE,
        page: initialPage
      },
      inProgress: false,
      feedRefreshInProgress: false,
      slotRefreshInProgress: [],
      articles: [],
      videoSettings: null,
      STICKY_BANNER_POSITION,
      articleVisibilityOptions: {
        callback: this.setArticleViewState,
        throttle: OBSERVER_CALLBACK_DEFAULT_THROTTLE
      },
      isArticleSlotInsideOrAboveViewport: false,
      VIDEO_BANNER_TYPE,
      lastModified: null
    }
  },
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    }),
    // isOnboardingFlowVisible() {
    //   return (
    //     process.client &&
    //     !this.isPreviewMode &&
    //     !localStorage.getItem(ONBOARDING_STORAGE_KEY.ALL_NEWS)
    //   )
    // },
    canonicalUrlWithoutPagination() {
      return this.$env.DOMAIN_URL
    }
  },
  methods: {
    ...mapActions({
      requestAllNews: 'news/requestAllNews'
    }),
    setArticleViewState(isVisible, entry) {
      this.isArticleSlotInsideOrAboveViewport = this.$_hydrationHelpers_isEntryTargetInsideOrAboveViewport(
        isVisible,
        entry
      )
    },
    getArticleVisibilityOptions(index) {
      const position = index + 1

      if (position === ARTICLE_POSITION_TO_DISPLAY_STICKY_BANNER) {
        return this.articleVisibilityOptions
      }

      return false
    },
    async getAllNews() {
      try {
        this.inProgress = true
        const { allNews, pagesCount } = await this.requestAllNews(
          this.pagination
        )
        this.articles = [...this.articles, ...allNews]
        this.slotRefreshInProgress = createPrefilledArray(
          this.articles.length,
          false
        )
        this.pagesCount = pagesCount
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
      }
    },
    getNextArticleChunk() {
      this.pagination.page++
      this.getAllNews()
    },
    // isVideoBannerVisible(index) {
    //   const position = index + 1
    //   return position === VIDEO_BANNER_POSITION
    // },
    isBannerVisible(index) {
      return this.$_bannerRotation_isBannerVisible({
        index,
        take: DEFAULT_TAKE,
        bannerPosition: BANNER_POSITION
      })
    },
    /**
     * Extra logic is necessary here to support a correct banner's rotation
     * specs: https://docs.google.com/document/d/1kDCjTGJJkBU-EGjYPcno1ZrRfVFx4bTk/edit#heading=h.tyjcwt
     */
    getBannerSettings(index) {
      const bannerNumber = this.$_bannerRotation_getBannerNumber({
        index,
        take: DEFAULT_TAKE,
        bannerPosition: BANNER_POSITION,
        bannerCount: BANNERS_COUNT
      })

      return BANNER_SETTINGS.ALL_NEWS[
        getInsideListBannerSettingsKey(bannerNumber)
      ]
    },
    isVideoVisible(index) {
      return this.videoSettings && index + 1 === VIDEO_POSITION
    },
    async fetchLoadedArticles() {
      const articleChunks = await this.$_articleDataHandler_fetchLoadedArticles(
        {
          action: this.requestAllNews,
          initialPage: this.initialPage,
          take: this.pagination.take,
          length: this.articles.length
        }
      )
      return articleChunks.reduce(
        (acc, { allNews }) => [...acc, ...allNews],
        []
      )
    },
    isInViewport(position) {
      return position < ARTICLE_SLOT_COUNT_IN_VIEWPORT
    }
  }
}
</script>

<style lang="scss" scoped>
.all-news__wrapper {
  .all-news__title {
    @include mobile {
      margin-bottom: 20px;
    }
  }

  .all-news__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .all-news__join-telegram {
    @include positionJoinTelegramIcon($absolute: false);
  }

  .all-news__card {
    margin-top: $gap-between-page-title-and-content-card;

    @include mobile {
      margin-top: 0;
    }
  }

  .all-news__article {
    margin-bottom: 20px;
  }

  /deep/ .article-list__item-wrapper:last-child .all-news__article {
    margin-bottom: 0;
  }

  .all-news__banner {
    margin: 30px 0;
  }

  .all-news__video-banner {
    margin: 30px 0 20px 0;

    @include mobile {
      margin-bottom: 30px;
    }
  }

  .all-news__video {
    margin: 40px 0;

    @include mobile {
      margin: 20px 0;
    }
  }

  .all-news__see-more-less {
    margin-top: 20px;
  }
}
</style>
