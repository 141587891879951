<template>
  <!-- Do not remove this wrapper as it is used in the scope of
       waitForElAppearsInDom -->
  <div class="ebook-form-modal__wrapper">
    <a-modal-wrapper
      ref="modal"
      :modal-name="$options.consts.MODAL.EBOOK_FORM"
      :activate-focus-trap-on-modal-open="false"
      class="ebook-form-modal"
      @open-modal="onOpenModal"
      @close-modal="onCloseModal"
    >
      <a-ebook-with-transition
        :ebook-data="ebook"
        :mediaQueryMap="$options.consts.EBOOK_MEDIA_QUERY_MAP.MODAL_EBOOK_MAP"
        :form-id="$options.consts.FORM_ID.EBOOK_MODAL"
        show-form-only
        @close-modal="closeModal"
      />
    </a-modal-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AModalWrapper, { MODAL } from 'modals/AModalWrapper'
import { FORM_ID } from 'enums/form-id'
import { EBOOK_MEDIA_QUERY_MAP } from 'shared/AEbook/enums'
import AEbookWithTransition from 'shared/AEbookWithTransition'
import { REFS } from 'enums/external-refs'
import { waitForElAppearsInDom } from '@fmpedia/helpers'

export default {
  name: 'AEbookFormModal',
  components: {
    AModalWrapper,
    AEbookWithTransition
  },
  consts: {
    MODAL,
    FORM_ID,
    EBOOK_MEDIA_QUERY_MAP
  },
  data() {
    return {
      isModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      ebook: 'ebooks/ebook'
    }),
    activateFocusTrapDataTrigger() {
      return this.isModalOpen && !!this.ebook
    }
  },
  watch: {
    async activateFocusTrapDataTrigger(newVal) {
      if (!newVal) return

      await waitForElAppearsInDom({
        dataRef: REFS.EBOOK_FORM,
        elementToSearchIn: this.$el
      })

      this.$refs.modal.activateFocusTrap()
    }
  },
  methods: {
    closeModal() {
      this.$helper.closeModal(MODAL.EBOOK_FORM)
    },
    onOpenModal() {
      this.isModalOpen = true
    },
    onCloseModal() {
      this.isModalOpen = false
    }
  }
}
</script>

<style lang="scss">
.ebook-form-modal {
  div.a-scrollable-content__parent-wrapper.vertical
    > div.a-scrollable-content__scroll-wrapper {
    padding-right: 0;
  }

  div.ebook__wrapper.modal-ebook div.ebook__form {
    @include mobile {
      padding-top: 15px;
    }
  }
}

.modal-wrapper__overlay.ebook-form-modal {
  z-index: 99999;
}
</style>
