<template>
  <a-collapse-transition>
    <client-only>
      <a-ebook v-if="ebookData" v-bind="ebookProps" v-on="$listeners" />
    </client-only>
  </a-collapse-transition>
</template>

<script>
import { mapGetters } from 'vuex'

import { propValidator, PROP_TYPES } from '~/utils/validators'
import AEbook from 'shared/AEbook/lazy.vue'
import ACollapseTransition from 'shared/ACollapseTransition'

export default {
  name: 'AEbookWithTransition',
  components: {
    ACollapseTransition,
    AEbook
  },
  props: {
    ebookData: propValidator([PROP_TYPES.OBJECT], false)
  },
  computed: {
    ...mapGetters({
      isEbookRequestInProgress: 'ebooks/isEbookRequestInProgress'
    }),
    ebookProps() {
      return { ...this.ebookData, ...this.$attrs }
    }
  }
}
</script>
