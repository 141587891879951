/**
 * Class modificators to manage different behavior on different media queries
 */
export const SLOT_STYLE = {
  STYLE_1: 'style-1', // general articleSlot styles for the most of pages
  STYLE_2: 'style-2' // styles for author page
}

export const ARTICLE_SLOT_PROPS = [
  'article-id',
  'title',
  'slug',
  'is-migrated',
  'is-session-wrap',
  'sponsorship-company',
  'category',
  'tldr',
  'is-pinned',
  'featured-image',
  'thumbnail-image',
  'featured-video',
  'author',
  'expanded-content',
  'terms',
  'published-on',
  'modified-on',
  'hide-category',
  'image-viewport-optimization',
  'image-srcset-location',
  'hide-publisher',
  'slot-style',
  'expandable',
  'title-html-tag'
]
