export const STEP = {
  OFFER: 'OFFER',
  FORM: 'FORM',
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE'
}

export const EBOOK_MEDIA_QUERY_MAP = {
  SIDEBAR_EBOOK_MAP: 'sidebar-ebook-map', // used on Sidebar
  MOBILE_EBOOK_MAP: 'mobile-ebook-map', // used on single article page
  EDUCATION_EBOOK_MAP: 'education-ebook-map', // used on education page
  MODAL_EBOOK_MAP: 'modal-ebook-map' // used on All News page in Modal
}

export const EBOOK_CLASS_NAME_BY_MEDIA_QUERY_MAP = {
  [EBOOK_MEDIA_QUERY_MAP.SIDEBAR_EBOOK_MAP]: 'sidebar-ebook',
  [EBOOK_MEDIA_QUERY_MAP.MOBILE_EBOOK_MAP]: 'mobile-ebook',
  [EBOOK_MEDIA_QUERY_MAP.EDUCATION_EBOOK_MAP]: 'education-ebook',
  [EBOOK_MEDIA_QUERY_MAP.MODAL_EBOOK_MAP]: 'modal-ebook'
}

export const FORM_APPEARANCE = {
  SLIDE_LEFT: 'slide-left',
  SLIDE_DOWN: 'slide-down'
}
