<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import ASpinner from 'shared/ASpinner'

export const SPINNER_ALIGNMENT = {
  TOP: 'TOP',
  CENTER: 'CENTER'
}

export default {
  name: 'AInProgress',
  components: { ASpinner },
  props: {
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false),
    renderWhileInProgress: propValidator([PROP_TYPES.BOOLEAN], false, true),
    alignSpinner: propValidator(
      [PROP_TYPES.STRING],
      false,
      SPINNER_ALIGNMENT.TOP,
      value => Object.values(SPINNER_ALIGNMENT).includes(value)
    )
  },

  computed: {
    spinnerClasses() {
      return [
        'in-progress__spinner',
        {
          center: this.alignSpinner === SPINNER_ALIGNMENT.CENTER
        }
      ]
    },
    wrapperClasses() {
      return [
        'in-progress__wrapper',
        {
          'in-progress': this.inProgress
        }
      ]
    }
  },
  render(h) {
    const content = this.$slots?.default?.[0] || null
    const spinner = h(ASpinner, { class: this.spinnerClasses })
    const contentWhileInProgress = this.renderWhileInProgress ? [content] : []
    const children = this.inProgress
      ? [...contentWhileInProgress, spinner]
      : [content]
    return h('div', { class: this.wrapperClasses }, children)
  }
}
</script>

<style lang="scss" scoped>
.in-progress__wrapper {
  position: relative;

  &::after {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($c--white, 0.7);
    cursor: progress;
    content: '';
    z-index: 1000;
    transition: background-color 0.3s;
  }

  &.in-progress::after {
    display: block;
  }
}

.in-progress__spinner {
  position: absolute;
  top: 20px;
  left: calc(50% - 16px); // consider spinner size = 32px
  transform: translateX(calc(-50% + 16px));

  &.center {
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
