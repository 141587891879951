<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export default {
  name: 'AUrlPagination',
  mixins: [hydrationHelpers],
  props: {
    itemSelector: propValidator([PROP_TYPES.STRING], false),
    pagination: propValidator([PROP_TYPES.OBJECT]),
    defaultTakeFn: propValidator([PROP_TYPES.FUNCTION], false),
    itemCount: propValidator([PROP_TYPES.NUMBER]),
    initialPage: propValidator([PROP_TYPES.NUMBER]),
    pagesCount: propValidator([PROP_TYPES.NUMBER]),
    nonPaginatedUrl: propValidator([PROP_TYPES.STRING]),
    disableScrollHandling: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  head() {
    return {
      link: this.$helper.getPaginationSeoLinks(
        this.nonPaginatedUrl,
        this.pagesCount,
        this.pageNumber
      )
    }
  },
  data() {
    return {
      isComponentDestroyed: false,
      pageNumber: this.initialPage + 1
    }
  },
  computed: {
    initialTake() {
      return this.$helper.checkIfFunction(this.defaultTakeFn)
        ? this.defaultTakeFn(this.initialPage)
        : this.pagination.take
    }
  },
  watch: {
    itemCount: {
      handler() {
        this.$nextTick(this.onScroll)
      }
    }
  },
  methods: {
    emitUpdatedPagination() {
      this.$emit('update:pagination', {
        ...this.pagination,
        page: this.pageNumber - 1
      })
    },
    getItemProps() {
      const items = document.querySelectorAll(this.itemSelector)
      return [...items].map(item => {
        const itemProps = item.getBoundingClientRect()
        return {
          top: itemProps.top,
          height: itemProps.height
        }
      })
    },
    getFirstItemBelowScreen() {
      const childProps = this.getItemProps()
      /**
       * Offset - is just correction to change page number when part of item from next page
       * partially appears from bottom
       * @type {number}
       */
      const offset = 50
      const childRelativePositions = childProps.map(childProp =>
        childProp.top + childProp.height >
        this.$_hydrationHelpers_windowHeight - offset
          ? 'below'
          : 'above'
      )
      return childRelativePositions.indexOf('below') + 1
    },
    getCurrentPage() {
      const firstItemBelowScreen = this.getFirstItemBelowScreen()

      if (this.$helper.checkIfFunction(this.defaultTakeFn)) {
        return this.getCurrentPageWithDefaultTake(firstItemBelowScreen)
      } else {
        return this.getCurrentPageWithGeneralTake(firstItemBelowScreen)
      }
    },
    getCurrentPageWithDefaultTake(firstItemBelowScreen) {
      let pageNumber = this.initialPage
      let count = 0
      do {
        const take = this.defaultTakeFn(pageNumber++)
        count += take
      } while (count < (firstItemBelowScreen || this.itemCount))

      return pageNumber
    },
    getCurrentPageWithGeneralTake(firstItemBelowScreen) {
      if (firstItemBelowScreen === 0) {
        return (
          Math.ceil(this.itemCount / this.pagination.take) + this.initialPage
        )
      }

      return (
        Math.floor((firstItemBelowScreen - 1) / this.pagination.take) +
        this.initialPage +
        1
      )
    },
    onScroll() {
      if (this.isComponentDestroyed) {
        return
      }

      const isMoreThanOnePageLoaded = this.itemCount > this.initialTake
      if (isMoreThanOnePageLoaded) {
        const page = this.getCurrentPage()
        if (page > 1 || this.getPageNumberFromUrl()) {
          this.setPageNumber(page)
        }
      }
    },
    setPageNumber(pageNumber) {
      if (!process.client) return

      const currentUrl = window.location.href

      const { query, hash } = this.$helper.parseUrl(currentUrl)

      const firstPagePath = `${this.nonPaginatedUrl}${query}${hash}`
      const paginatedPagePath = `${this.$helper.removeEndingSlashes(
        this.nonPaginatedUrl
      )}/page/${pageNumber}/`

      const newUrl = pageNumber === 1 ? firstPagePath : paginatedPagePath

      if (newUrl === currentUrl) return

      this.pageNumber = pageNumber
      this.emitUpdatedPagination()
      window.history.replaceState(null, '', newUrl)
    },
    getPageNumberFromUrl() {
      const urlPath = window.location.href
      return this.$helper.getPageNumberFromUrl(urlPath)
    }
  },
  render() {
    return null
  },
  mounted() {
    const currentPage = this.getPageNumberFromUrl() || 1
    this.setPageNumber(currentPage)
    this.emitUpdatedPagination()
    if (this.disableScrollHandling || !this.itemSelector) return

    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    this.isComponentDestroyed = true
    if (this.disableScrollHandling) return

    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
