import { ROUTE_NAME } from 'enums/routes'

export const VIDEO_BANNER_TYPE = {
  SIDEBAR: 'SIDEBAR',
  ALL_NEWS: 'ALL_NEWS',
  CATEGORY: 'CATEGORY',
  ARTICLE: 'ARTICLE',
  ALL_NEWS_SIDEBAR: 'ALL_NEWS_SIDEBAR'
}

export const VIDEO_BANNER_SETTINGS_BY_BANNER_TYPE = {
  [VIDEO_BANNER_TYPE.SIDEBAR]: {
    playerApiId: '1',
    playerIdDesktop: '105990',
    playerIdMobile: null
  },
  [VIDEO_BANNER_TYPE.ALL_NEWS]: {
    playerApiId: '2',
    playerIdDesktop: '105988',
    playerIdMobile: '105989'
  },
  [VIDEO_BANNER_TYPE.CATEGORY]: {
    playerApiId: '3',
    playerIdDesktop: '105988',
    playerIdMobile: '105989'
  },
  [VIDEO_BANNER_TYPE.ARTICLE]: {
    playerApiId: '4',
    playerIdDesktop: '105986',
    playerIdMobile: '105987'
  },
  [VIDEO_BANNER_TYPE.ALL_NEWS_SIDEBAR]: {
    playerApiId: '5',
    playerIdDesktop: '110066',
    playerIdMobile: null
  }
}

export const SIDEBAR_PAGES_WITH_VIDEO_BANNER = [
  ROUTE_NAME.ECONOMIC_CALENDAR,
  ROUTE_NAME.LIVE_QUOTES,
  ROUTE_NAME.LIVE_QUOTES_ASSET,
  ROUTE_NAME.LIVE_CHARTS,
  ROUTE_NAME.EDUCATION
]
