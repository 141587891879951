var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-news__wrapper"},[_c('a-visibility',{attrs:{"hide":"","on":[_vm.$breakpoint.mobile]}},[_c('a-lazy-hydrate',{attrs:{"when-visible":""}},[_c('h1',{staticClass:"all-news__title click-spam-left-padding title"},[_vm._v("\n        Forexlive Latest News\n      ")])])],1),_vm._v(" "),_c('a-card',{staticClass:"all-news__card skip-mobile-paddings"},[_c('a-visibility',{attrs:{"show":"","on":[_vm.$breakpoint.mobile]}},[_c('div',{staticClass:"all-news__title-wrapper"},[_c('h1',{staticClass:"all-news__title click-spam-left-padding title"},[_vm._v("\n          Forexlive Latest News\n        ")]),_vm._v(" "),_c('a-join-telegram',{staticClass:"all-news__join-telegram"})],1)]),_vm._v(" "),_c('a-article-list',{staticClass:"all-news__article-list",attrs:{"articles":_vm.articles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var article = ref.article;
var position = ref.position;
return [_c('a-lazy-hydrate',{attrs:{"when-visible":""}},[_c('a-article-slot',_vm._b({directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.getArticleVisibilityOptions(position)),expression:"getArticleVisibilityOptions(position)"}],staticClass:"all-news__article",attrs:{"expandable":true,"image-viewport-optimization":_vm.isInViewport(position)},scopedSlots:_vm._u([{key:"bottom-expanded-content",fn:function(ref){
var isContentExpanded = ref.isContentExpanded;
return (isContentExpanded)?_c('a-banner',{staticClass:"all-news__banner",attrs:{"banner-settings":_vm.BANNER_SETTINGS.ALL_NEWS.EXPANDED_CONTENT_BANNER}}):_vm._e()}}],null,true)},'a-article-slot',article,false))],1)]}},{key:"banner",fn:function(ref){
var position = ref.position;
return [(_vm.isBannerVisible(position))?_c('a-banner',{staticClass:"all-news__banner",attrs:{"banner-settings":_vm.getBannerSettings(position),"use-random-banner-id":""}}):_vm._e()]}},{key:"video",fn:function(ref){
var position = ref.position;
return [(_vm.isVideoVisible(position))?_c('a-video-slot',{staticClass:"all-news__video",attrs:{"video-settings":_vm.videoSettings}}):_vm._e()]}}])}),_vm._v(" "),_c('a-see-more-less',{staticClass:"all-news__see-more-less",attrs:{"slot":"bottom-section","page":_vm.pagination.page,"pages-count":_vm.pagesCount,"is-expanded":false,"in-progress":_vm.inProgress},on:{"input":_vm.getNextArticleChunk},slot:"bottom-section"})],1),_vm._v(" "),_c('a-url-pagination',{attrs:{"item-selector":_vm.ARTICLE_SLOT_SELECTOR,"item-count":_vm.articles.length,"pagination":_vm.pagination,"initial-page":_vm.initialPage,"pages-count":_vm.pagesCount,"non-paginated-url":_vm.canonicalUrlWithoutPagination},on:{"update:pagination":function($event){_vm.pagination=$event}}}),_vm._v(" "),_c('a-refresh-listener',{attrs:{"articles":_vm.articles,"feed-refresh-in-progress":_vm.feedRefreshInProgress,"slot-refresh-in-progress":_vm.slotRefreshInProgress,"fetch-data-fn":_vm.fetchLoadedArticles,"settings":_vm.$options.consts.AUTO_REFRESH_SETTINGS.ALL_NEWS,"target-category-slug":_vm.$options.consts.SPECIAL_REFRESH_CATEGORY_SLUG.ALL_NEWS},on:{"update:articles":function($event){_vm.articles=$event},"update:feedRefreshInProgress":function($event){_vm.feedRefreshInProgress=$event},"update:feed-refresh-in-progress":function($event){_vm.feedRefreshInProgress=$event},"update:slotRefreshInProgress":function($event){_vm.slotRefreshInProgress=$event},"update:slot-refresh-in-progress":function($event){_vm.slotRefreshInProgress=$event},"feed-refreshed":_vm.$_disqusCommentCount_updateCommentCounts}}),_vm._v(" "),(_vm.isArticleSlotInsideOrAboveViewport)?_c('a-sticky-banner',{attrs:{"sticky-position":_vm.STICKY_BANNER_POSITION.BOTTOM,"banner-settings":_vm.BANNER_SETTINGS.ALL_NEWS.STICKY_TO_BOTTOM}}):_vm._e(),_vm._v(" "),_c('a-pagination-links-for-google',{attrs:{"page":_vm.pagination.page,"pages-count":_vm.pagesCount,"non-paginated-url":_vm.canonicalUrlWithoutPagination}}),_vm._v(" "),_c('a-ebook-form-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }