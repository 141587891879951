<template>
  <div class="article-list__wrapper">
    <transition-group name="list">
      <div
        v-for="(article, index) in articles"
        :key="`${article.slug}${index}`"
        class="article-list__item-wrapper"
      >
        <slot :article="article" :position="index" />
        <slot name="disqus" :position="index" />
        <slot name="banner" :position="index" />
        <slot name="video" :position="index" />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AArticleList',
  props: {
    articles: propValidator([PROP_TYPES.ARRAY])
  }
}
</script>

<style lang="scss">
/* TODO animation requirements are to be discussed with the client
//.list-enter-active,
//.list-leave-active {
//  transition: all 1s;
//}
//
//.list-enter,
//.list-leave-to {
//  opacity: 0;
//} */
</style>
