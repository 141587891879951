<template>
  <div class="pagination-links__wrapper">
    <a v-if="nextLink" :href="nextLink">Next</a>
    <a v-if="previousLink" :href="previousLink">Previous</a>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

const SLASH = '/'

export default {
  name: 'APaginationLinksForGoogle',
  props: {
    page: propValidator([PROP_TYPES.NUMBER]),
    pagesCount: propValidator([PROP_TYPES.NUMBER]),
    nonPaginatedUrl: propValidator([PROP_TYPES.STRING])
  },
  computed: {
    visiblePageNumber() {
      return this.page + 1
    },
    linkWithoutEndingSlash() {
      return this.$helper.removeEndingSlashes(this.nonPaginatedUrl)
    },
    optionalEndingSlash() {
      return this.$helper.isStringEndsWith(SLASH, this.nonPaginatedUrl)
        ? SLASH
        : ''
    },
    nextPageNumber() {
      if (this.visiblePageNumber >= this.pagesCount) return null

      return this.visiblePageNumber + 1
    },
    previousPageNumber() {
      if (this.visiblePageNumber < 2) return null

      return this.visiblePageNumber - 1
    },
    nextLink() {
      if (this.nextPageNumber === null) return null

      return `${this.linkWithoutEndingSlash}/page/${this.nextPageNumber}/`
    },
    previousLink() {
      if (this.previousPageNumber === null) return null

      const pagination =
        this.previousPageNumber === 1
          ? null
          : `page/${this.previousPageNumber}/`

      return pagination
        ? `${this.linkWithoutEndingSlash}/${pagination}`
        : `${this.linkWithoutEndingSlash}${this.optionalEndingSlash}`
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-links__wrapper {
  display: none;
}
</style>
